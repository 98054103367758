import { makeAutoObservable } from "mobx"
// constants

export default class Match {
    match_id = null;
    question_id = null;
    left_user_id = null;
    right_user_id = null;
    status = null;
    created_at = null;
    updated_at = null;

    // eagered
    initialMessages = []; // what comes from the eager
    question = null;
    left_user = null;
    right_user = null;

    unresolvedMessages = []; // added messages directly from ably

    constructor(json) {
        makeAutoObservable(this);

        if (json) {
            this.updateFromJson(json);
        }
    }

    get messages() {
        return [...this.initialMessages, ...this.unresolvedMessages];
    }


    unreadMessagesCount = (currentUserId) => {
        const unreadMessages = this.messages.filter((message) => !message.is_read && message.author_id !== currentUserId);

        return unreadMessages ? unreadMessages.length : 0;
    }

    // first unread message sent by other participant (not currentUserId)
    firstUnreadMessage = (currentUserId) => {
        return this.messages.find((message) => !message.is_read && message.author_id !== currentUserId);
    }

    addUnresolvedMessage = (unresolvedMessage) => {
        this.unresolvedMessages.push(unresolvedMessage);
    }

    resetUnresolvedMessages = () => {
        this.unresolvedMessages = [];
    };

    readUnresolvedMessage = (message_id) => {
        this.unresolvedMessages = this.unresolvedMessages.map((unresolvedMessage) => {
            if (+unresolvedMessage.message_id === +message_id) {
                return {
                    ...unresolvedMessage,
                    is_read: true,
                }
            }

            return unresolvedMessage;
        })
    }

    updateFromJson(questionJson) {
        // to prevent duplicates between unresolvedMessages and initialMessages
        this.resetUnresolvedMessages();

        for (const prop in questionJson) {
            if (prop in questionJson && prop in this) {
                if (prop === "messages") {
                    this.initialMessages = questionJson[prop]
                } else {
                    this[prop] = questionJson[prop];
                }
            }
        }
    }
}