import { useContext } from "react";
import { StoresContext } from "./StoresContext";

export const useStoresContext = () => {
    const context = useContext(StoresContext);

    if (!context) throw new Error('useStoresContext context must be use inside StoresProvider');

    return context;
};
