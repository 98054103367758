import { makeAutoObservable } from "mobx"

export default class Question {
    question_id = null;
    content = null;
    score = null;
    is_active = false;
    is_pending = false;
    author_name = null;
    expiration_date = null;

    constructor(json) {
        makeAutoObservable(this);

        if (json) {
            this.updateFromJson(json);
        }
    }

    updateFromJson(questionJson) {
        for (const prop in questionJson) {
            if (prop in questionJson && prop in this) {
                this[prop] = questionJson[prop];
            }
        }
    }
}