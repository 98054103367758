// question
export const NEW_ACTIVE_QUESTION = "new_active_question"
// matches
export const MATCH_REQUEST = "match_request";
export const MATCH_FOUND = "match_found";
export const MATCH_NOT_FOUND = "match_not_found";
export const MATCH_INITIALIZATION = "match_initialization";
// messages
export const CREATE_MESSAGE_REQUEST = "create_message_request";
export const MESSAGE_CREATED = "message_created";

export const READ_MESSAGE_REQUEST = "read_message_request";
export const READ_MESSAGE_CONFIRMED = "read_message_confirmed";

export const READ_MATCH_MESSAGES_REQUEST = "read_match_messages_request";
export const READ_MATCH_MESSAGES_CONFIRMED = "read_match_messages_confirmed";

// typing
export const USER_TYPING = "user_typing";
export const USER_STOPPED_TYPING = "user_stopped_typing";

// unread message count notification
export const UPDATED_UNREAD_MESSAGE_COUNT = "updated_unread_message_count";

// refresh
export const REFRESH_USER = "refresh_user";
export const REFRESH_ALL_USERS = "refresh_all_users";
export const REFRESH_ANSWERS = "refresh_answers";

// explicit ably channels
export const ALL_USERS_CHANNEL = "all_users_channel";
export const ALL_USERS_MATCHING_CHANNEL = "all_users_matching_channel";
export const ALL_USERS_QUESTION_CHANNEL = "all_users_question_channel";