import { makeObservable, observable, action, runInAction, computed } from "mobx"
import { Question } from "../../models/question";
import apiLayer from '../../services/api-layer/ApiLayer';

class QuestionStore {
    isHydrated = false;
    questionMap = new Map();
    activeQuestion = null;

    constructor() {
        makeObservable(this, {
            isHydrated: observable,
            questionMap: observable,
            activeQuestion: observable,
            hydrate: action,
            clearQuestions: action,
            updateFromJson: action,
            setActiveQuestion: action,
            reset: action,
            questions: computed,
        });
    }

    get questions() {
        return Array.from(this.questionMap.values());
    }

    clearQuestions() {
        this.questionMap = new Map();
    }

    async hydrate() {
        this.clearQuestions();

        const response = await apiLayer.searchQuestions();

        const questions = response.data.questions;

        questions.forEach((question) => {
            this.updateFromJson(question);
        });

        runInAction(() => {
            this.isHydrated = true;
        })
    }

    updateFromJson(json) {
        let question = this.questionMap.get(json.question_id);

        if (question && question.updateFromJson) {
            question.updateFromJson(json);
        } else {
            this.questionMap.set(json.question_id, new Question(json));

            question = this.questionMap.get(json.question_id)
        }

        // set if active question
        if (question.is_active) {
            this.setActiveQuestion(question);
        }

    }

    setActiveQuestion = (question) => {
        this.activeQuestion = question;
    }

    reset = () => {
        this.activeQuestion = null;
        this.questionMap = new Map();
        this.isHydrated = false;
    }
}

const questionStore = new QuestionStore();

export default questionStore;