// import { observer } from 'mobx-react';
// import { useEffect, useState } from 'react';
// import { Outlet } from 'react-router-dom';
// // @mui
// import { Box } from '@mui/material';
// // hooks
// import useResponsive from '../../hooks/useResponsive';
// //
// import Main from './Main';
// import Header from './header';
// import NavVertical from './nav/NavVertical';
// import NavHorizontal from './nav/NavHorizontal';
// // stores
// import { useStoresContext } from '../../stores/useStoresContext';
// // constants
// import { ALL_USERS_CHANNEL, REFRESH_ALL_USERS, REFRESH_USER, UPDATED_UNREAD_MESSAGE_COUNT } from '../../constants/ably-events';
// // services
// import apiLayer from '../../services/api-layer/ApiLayer';
// // auth
// import { useAuthContext } from '../../auth/useAuthContext';
// // hooks
// import { useChannel } from '../../hooks/useChannel';

// // ----------------------------------------------------------------------
// const filename = "/layouts/app/AppLayout.js";

// const AppLayout = observer(() => {
//     const { layoutStore } = useStoresContext();

//     const { user, refreshUser } = useAuthContext();

//     const [unreadMessageCount, setUnreadMessageCount] = useState(0);
//     const [isFetching, setIsFetching] = useState(false);

//     const isDesktop = useResponsive('up', 'lg');

//     const currentUserId = user.user_id;

//     const [_channel, _ably] = useChannel({
//         channelName: ALL_USERS_CHANNEL,
//         eventNames: [REFRESH_ALL_USERS],
//         callbackOnMessageMap: {
//             [REFRESH_ALL_USERS]: async (message, _channel) => {
//                 await refreshUser();
//             },
//         },
//     });

//     const [_userChannel, _userChannelAbly] = useChannel({
//         channelName: `user-${currentUserId}`,
//         eventNames: [REFRESH_USER, UPDATED_UNREAD_MESSAGE_COUNT],
//         callbackOnMessageMap: {
//             [REFRESH_USER]: async (message, _channel) => {
//                 const data = message.data;

//                 if (+currentUserId === +data.refresh_user_id) {
//                     await refreshUser();
//                 }
//             },
//             [UPDATED_UNREAD_MESSAGE_COUNT]: (message, _channel) => {
//                 const data = message.data;
//                 // only update if this update is for the current user
//                 if (+data.user_id === +currentUserId) {
//                     setUnreadMessageCount(+data.unreadMessageCount);
//                 }
//             },
//         },
//     });

//     useEffect(() => {
//         const getInitialUnreadMessageCount = async () => {
//             try {
//                 setIsFetching(true);

//                 const unreadMessageCountRes = await apiLayer.getUnreadMessageCount();

//                 if (unreadMessageCountRes.data.err) {
//                     console.error(`ClientLogger: Error in getInitialUnreadMessageCount in ${filename}`, {
//                         filename: filename,
//                         function: "getInitialUnreadMessageCount",
//                         message: "Error getting unread message count",
//                         rawError: unreadMessageCountRes.data.err,
//                     });
//                 }

//                 const unreadMessageCount = +unreadMessageCountRes.data.count;

//                 setUnreadMessageCount(unreadMessageCount);
//             } catch (err) {
//                 console.error(`ClientLogger: Error in getInitialUnreadMessageCount in ${filename}`, {
//                     filename: filename,
//                     function: "getInitialUnreadMessageCount",
//                     message: "Error getting unread message count",
//                     rawError: err,
//                 });
//             } finally {
//                 setIsFetching(false);
//             }
//         }

//         getInitialUnreadMessageCount();
//     }, []);

//     if (!isDesktop) {
//         return (
//             <>
//                 {!layoutStore.shouldHideAppHeader && (
//                     <Header isNavHorizontal={true} />
//                 )}

//                 <Main
//                     isNavHorizontal={true}
//                     hideTopPadding={layoutStore.shouldHideAppHeader}
//                     hideBottomPadding={layoutStore.shouldHideHorizontalNav}
//                 >
//                     <Outlet />
//                 </Main>

//                 {!layoutStore.shouldHideHorizontalNav && (
//                     <NavHorizontal unreadMessageCount={unreadMessageCount} />
//                 )}
//             </>
//         );
//     }

//     return (
//         <>
//             {!layoutStore.shouldHideAppHeader && (
//                 <Header />
//             )}

//             <Box
//                 sx={{
//                     display: { lg: 'flex' },
//                     height: "100%",
//                 }}
//                 data-qa-id={"app-layout-container"}
//             >
//                 <NavVertical unreadMessageCount={unreadMessageCount || 0} />

//                 <Main hideTopPadding={layoutStore.shouldHideAppHeader}>
//                     <Outlet />
//                 </Main>
//             </Box>
//         </>
//     );
// })
// export default AppLayout;


import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
//
import Main from './Main';
// stores
import { useStoresContext } from '../../stores/useStoresContext';
import { pxToRem } from '../../theme/typography';
// constants
// services
// auth
// hooks

// ----------------------------------------------------------------------
const filename = "/layouts/app/AppLayout.js";

const AppLayout = observer(() => {
    const { layoutStore } = useStoresContext();

    const isDesktop = useResponsive('up', 'lg');

    if (!isDesktop) {
        return (
            <Box
                sx={{
                    display: { lg: 'flex' },
                    height: "100%",
                    backgroundSize: `${pxToRem(20)} ${pxToRem(20)}`,
                    backgroundImage: `
                        linear-gradient(to right, rgba(217,215,215, 0.8) 1px, transparent 1px),
                        linear-gradient(to bottom, rgba(217,215,215, 0.8) 1px, #FAF9F9 1px)
                    `
                }}
                data-qa-id={"app-layout-container"}
            >
                <Main
                    isNavHorizontal={true}
                    hideTopPadding={layoutStore.shouldHideAppHeader}
                    hideBottomPadding={layoutStore.shouldHideHorizontalNav}
                >
                    <Outlet />
                </Main>
            </Box>


        );
    }

    return (
        <>

            <Box
                sx={{
                    display: { lg: 'flex' },
                    height: "100%",
                    backgroundSize: `${pxToRem(26)} ${pxToRem(26)}`,
                    backgroundImage: `
                        linear-gradient(to right, rgba(217,215,215, 0.8) 1px, transparent 1px),
                        linear-gradient(to bottom, rgba(217,215,215, 0.8) 1px, #FAF9F9 1px)
                    `
                }}
                data-qa-id={"app-layout-container"}
            >
                <Main hideTopPadding={layoutStore.shouldHideAppHeader}>
                    <Outlet />
                </Main>
            </Box >
        </>
    );
})
export default AppLayout;