import { makeObservable, observable, action, runInAction, computed } from "mobx"
import { Match } from "../../models/match";
import apiLayer from '../../services/api-layer/ApiLayer';
import { MESSAGE_WITH_QUESTION_QUERY } from "../../constants/query/index.js"

class MatchStore {
    isHydrated = false;
    matchMap = new Map();

    constructor() {
        makeObservable(this, {
            isHydrated: observable,
            matchMap: observable,
            hydrate: action,
            refreshMatch: action,
            clearMatches: action,
            updateFromJson: action,
            getMatchByUserId: action,
            reset: action,
            matches: computed,
        });
    }

    get matches() {
        return Array.from(this.matchMap.values());
    }

    clearMatches() {
        this.matchMap = new Map();
    }

    async hydrate() {
        this.clearMatches();

        const matchQuery = {
            fields: {
                messages: MESSAGE_WITH_QUESTION_QUERY,
                left_user: true,
                right_user: true,
                question: true,
            }
        };

        const response = await apiLayer.searchMatches(matchQuery);

        const matches = response.data.matches;

        matches?.forEach((match) => {
            this.updateFromJson(match);
        });

        runInAction(() => {
            this.isHydrated = true;
        })
    }

    // search list of current user's matches and fetch relevant one
    async getMatchByUserId(targetUserId) {
        let relevantMatch = this.matches.find((match) => {
            const leftUserId = match.left_user.user_id;
            const rightUserId = match.right_user.user_id;

            return [leftUserId, rightUserId].includes(targetUserId);
        });

        if (relevantMatch) {
            return relevantMatch;
        }

        // hydrate again to find relevant match
        await this.hydrate();

        relevantMatch = this.matches.find((match) => {
            const leftUserId = match.left_user.user_id;
            const rightUserId = match.right_user.user_id;

            return [leftUserId, rightUserId].includes(targetUserId);
        });

        return relevantMatch;
    }

    // find single match and refresh or add
    async refreshMatch(matchId) {
        const matchQuery = {
            fields: {
                messages: MESSAGE_WITH_QUESTION_QUERY,
                left_user: true,
                right_user: true,
                question: true,
            }
        };

        const response = await apiLayer.findOneMatch({ match_id: matchId, query: matchQuery });

        const foundMatch = response.data.match;

        if (foundMatch) {
            this.updateFromJson(foundMatch);
        }
    }

    updateFromJson(json) {
        let match = this.matchMap.get(json.match_id);

        if (match && match.updateFromJson) {
            match.updateFromJson(json);
        } else {
            this.matchMap.set(json.match_id, new Match(json));
        }
    }

    reset = () => {
        this.matchMap = new Map();
        this.isHydrated = false;
    }
}

const matchStore = new MatchStore();

export default matchStore;