import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

const filename = "/stores/StoresContext.js";

export const StoresContext = createContext();

export function StoresProvider({ stores, children }) {
    const [isReady, setIsReady] = useState(false);

    const initialize = useCallback(async () => {
        try {
            // hydrate necessary stores and any other steps

            setIsReady(true);
        } catch (error) {
            console.error(`ClientLogger: Error in initialize in ${filename}`, {
                filename: filename,
                function: "initialize",
                message: "Error initializing stores",
                rawError: error,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        initialize();
    }, [initialize]);

    const resetStores = () => {
        stores.questionStore.reset();
        stores.questionSubscriptionStore.reset();
        stores.matchStore.reset();
        stores.layoutStore.reset();
    }

    const memoizedValue = useMemo(
        () => ({
            isReady,
            questionStore: isReady ? stores.questionStore : null,
            questionSubscriptionStore: isReady ? stores.questionSubscriptionStore : null,
            matchStore: isReady ? stores.matchStore : null,
            layoutStore: stores.layoutStore,
            resetStores: resetStores,
        }),
        [isReady, stores.questionStore, stores.questionSubscriptionStore, stores.matchStore, stores.layoutStore]
    );

    return <StoresContext.Provider value={memoizedValue}>{children}</StoresContext.Provider>;
}