// i18n
import './locales/i18n';

// lightbox
/* eslint-disable import/no-unresolved */
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// editor
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

import React from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Router from './routes';
import { AuthProvider } from './auth/JwtContext';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import { ThemeSettings, SettingsProvider } from './components/settings';
import SnackbarProvider from './components/snackbar';
// stores
import { StoresProvider } from './stores/StoresContext';
import stores from './stores';

import { log } from '@logtail/next';

const App = observer(() => {
  log.info("RUNNING FRONT END APP!", { userId: 1 });

  return (
    <AuthProvider>
      <HelmetProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <BrowserRouter>
              <ThemeProvider>
                <ThemeSettings>
                  <ThemeLocalization>
                    <SnackbarProvider>
                      <StoresProvider stores={stores}>
                        <Router />
                      </StoresProvider>
                    </SnackbarProvider>
                  </ThemeLocalization>
                </ThemeSettings>
              </ThemeProvider>
            </BrowserRouter>
          </SettingsProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </AuthProvider>
  );
})

export default App;
