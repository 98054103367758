import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';
// config
import { HEADER, NAV } from '../../config-global';

// ----------------------------------------------------------------------

const SPACING = 8;

Main.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
};

// export default function Main({ children, sx, isNavHorizontal = false, hideBottomPadding = false, hideTopPadding = false, ...other }) {
//   if (isNavHorizontal) {
//     return (
//       <Box
//         component="main"
//         sx={{
//           pt: hideTopPadding ? " inherit" : `${HEADER.H_MOBILE + SPACING}px`,
//           pb: hideBottomPadding ? " inherit" : `${HEADER.H_MOBILE + SPACING}px`,
//           height: "100%",
//         }}
//         data-qa-id={"main-container"}
//       >
//         {children}
//       </Box>
//     );
//   }

//   return (
//     <Box
//       component="main"
//       sx={{
//         flexGrow: 1,
//         px: 2,
//         pt: hideTopPadding ? "inherit" : `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
//         width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
//         height: "100%",
//         ...sx,
//       }}
//       {...other}
//       data-qa-id={"main-container"}
//     >
//       {children}
//     </Box>
//   );
// }


export default function Main({ children, sx, isNavHorizontal = false, hideBottomPadding = false, hideTopPadding = false, ...other }) {
  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          pt: hideTopPadding ? " inherit" : `${HEADER.H_MOBILE + SPACING}px`,
          pb: hideBottomPadding ? " inherit" : `${HEADER.H_MOBILE + SPACING}px`,
          height: "100%",
        }}
        data-qa-id={"main-container"}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        px: 2,
        width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
        height: "100%",
        ...sx,
      }}
      {...other}
      data-qa-id={"main-container"}
    >
      {children}
    </Box>
  );
}
