import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
// config
import { API_URL } from '../config-global';
import cookies from './cookies';
import { PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------
const filename = "/utils/axios.js";

const axiosInstance = axios.create({ baseURL: API_URL });

// ensure that we can set cookies 
axiosInstance.defaults.withCredentials = true;

const handleForceLogout = async () => {
  const hasAccessToken = cookies.get('authSession');

  if (hasAccessToken) {
    await axiosInstance.get('/auth/logout');
  }

  window.location.href = PATH_AUTH.entry;

  setTimeout(() => {
    window.location.reload();
  }, 100);
}

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status) {

      if (error.response.status === StatusCodes.UNAUTHORIZED) {
        // refreshTokenID is true when refreshToken exists
        const hasRefreshToken = cookies.get('refreshTokenID');

        if (!error.config?.noRefresh && hasRefreshToken) {
          return axiosInstance.post('/auth/refresh', {
            withCredentials: true,
            noRefresh: true,
          }).then(() => {
            // tries the request again after refreshing
            return axiosInstance(error.config, { noRefresh: true });
          })
        } else {
          await handleForceLogout();
        }
      } else if (error.response.status === StatusCodes.FORBIDDEN) {
        console.error(`ClientLogger: Error in axiosInstance in ${filename}`, {
          filename: filename,
          function: "axiosInstance",
          message: `Error with request - ${StatusCodes.FORBIDDEN}`,
          rawError: error,
        });

        await handleForceLogout();
      } else {
        return error.response;
      }
    }
  }
);

export default axiosInstance;
