import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { PATH_AUTH } from '../routes/paths';

import axios from '../utils/axios';
import cookies from '../utils/cookies';
import { attemptRefresh } from './utils';

// ----------------------------------------------------------------------

const filename = "/auth/JwtContext.js";

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === 'REFRESH_USER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const checkUserAccess = async ({ onSuccess, onFailure }) => {
    // authSession is true when accessToken exists
    const hasAccessToken = cookies.get('authSession');

    if (hasAccessToken) {
      if (onSuccess) {
        await onSuccess();
      }

      return true;
    }

    if (onFailure) {
      await onFailure();
    }

    return false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const onInitialDispatchSuccess = useCallback(async () => {
    // authenticate and pass user object
    const response = await axios.get('/auth/me');

    const { user } = response.data;

    dispatch({
      type: 'INITIAL',
      payload: {
        isAuthenticated: true,
        user,
      },
    });
  }, [])

  const initialize = useCallback(async () => {
    try {
      let userHasAccess = await checkUserAccess({ onSuccess: onInitialDispatchSuccess });

      if (!userHasAccess) {
        // refreshTokenID is true when refreshToken exists
        const hasRefreshToken = cookies.get('refreshTokenID');

        if (!hasRefreshToken) {
          return dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }

        // attempt to refresh and check for user access again
        const { success } = await attemptRefresh();

        if (success) {
          await checkUserAccess({
            onSuccess: onInitialDispatchSuccess,
            onFailure: () => {
              dispatch({
                type: 'INITIAL',
                payload: {
                  isAuthenticated: false,
                  user: null,
                },
              });
            }
          });
        } else {
          // in case of unsuccessful refresh
          return dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }
    } catch (error) {
      console.error(`ClientLogger: Error in initialize in ${filename}`, {
        filename: filename,
        function: "initialize",
        message: "Error initializing app",
        rawError: error,
      });

      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);


  // SEND OTP
  const sendOTP = useCallback(async (phone) => {
    const response = await axios.post('/auth/sendOTP', {
      phone
    });

    return response.data;
  }, []);

  // Verify OTP
  const verifyOTP = useCallback(async ({ phone, hash, otp }) => {
    const response = await axios.post('/auth/verifyOTP', {
      phone,
      hash,
      otp
    });

    return response.data;
  }, []);


  // LOGIN
  const login = useCallback(async () => {
    await checkUserAccess({
      onSuccess: async () => {
        // authenticate and pass user object
        const response = await axios.get('/auth/me');

        const { user } = response.data;

        dispatch({
          type: 'LOGIN',
          payload: {
            user,
          },
        });
      },
      onFailure: () => window.location.href = PATH_AUTH.entry,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // REGISTER
  const register = useCallback(async ({ phone, date_of_birth }) => {
    await axios.post('/auth/register', {
      phone,
      date_of_birth,
    });

    dispatch({
      type: 'REGISTER',
      payload: {
        user: null,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    await axios.get('/auth/logout');

    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // REFRESH USER
  const refreshUser = useCallback(async () => {
    // authenticate and pass user object
    const response = await axios.get('/auth/me');

    const { user } = response.data;

    dispatch({
      type: 'REFRESH_USER',
      payload: {
        user,
      },
    });
  }, []);



  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
      sendOTP,
      verifyOTP,
      refreshUser,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, login, logout, register, sendOTP, verifyOTP, refreshUser]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
