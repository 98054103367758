// import { Navigate, useRoutes } from 'react-router-dom';
// import GuestGuard from '../auth/GuestGuard';
// import AuthGuard from '../auth/AuthGuard';
// import AdminGuard from '../auth/AdminGuard';
// import {
//     // Auth
//     AuthEntryPage,
//     RegisterPage,
//     AdminPage,
//     HomePage,
//     ConversationsPage,
//     AccountPage,
//     Page403, 
//     NoAccessProfilePage,
//     NoMatchFoundPage,
// } from './elements';
// // layouts
// import AppLayout from '../layouts/app';
// import CompactLayout from '../layouts/compact/CompactLayout';
// // config
// import { PATH_AFTER_LOGIN } from '../config-global';
// // sections
// import ConversationsDetailView from '../sections/conversations/detail-view/ConversationsDetailView';
// import { Profile } from '../sections/profile';

// export default function Router() {
//     return useRoutes([
//         // Auth Login/Signup
//         {
//             path: 'auth',
//             children: [
//                 {
//                     path: 'entry',
//                     element: (
//                         <GuestGuard>
//                             <AuthEntryPage />
//                         </GuestGuard>
//                     ),
//                 },
//                 {
//                     path: 'register',
//                     element: (
//                         <GuestGuard>
//                             <RegisterPage />
//                         </GuestGuard>
//                     )
//                 },
//             ],
//         },
//         // App Entry Point
//         {
//             path: '/',
//             element: (
//                 <AuthGuard>
//                     <AppLayout />
//                 </AuthGuard>
//             ),
//             children: [
//                 { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
//                 { path: 'home', element: <HomePage /> },
//                 {
//                     path: 'conversations',
//                     children: [
//                         { element: <ConversationsPage />, index: true },
//                         { path: ':matchId', element: <ConversationsDetailView /> },
//                     ],
//                 },
//                 {
//                     path: 'profile',
//                     children: [
//                         { element: <Profile />, index: true },
//                         { path: ':username', element: <Profile /> },
//                     ],
//                 },
//                 { path: 'settings', element: <AccountPage /> },
//             ]
//         },
// // Protected Admin Interface
// {
//     path: '/admin',
//     element: (
//         <AdminGuard>
//             <AdminPage />
//         </AdminGuard>
//     ),
// },
//         // Error, maintenance pages, etc
//         {
//             element: <CompactLayout />,
//             children: [
//                 { path: '403', element: <Page403 /> },
//                 { path: 'no-profile-access', element: <NoAccessProfilePage />},
//                 { path: 'no-match-found', element: <NoMatchFoundPage />},
//             ],
//         },
//     ])
// }



import { Navigate, useRoutes } from 'react-router-dom';
import {
    HomePage,
    AdminPage,
} from './elements';
// layouts
import AppLayout from '../layouts/app';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
// sections

export default function Router() {
    return useRoutes([
        // App Entry Point
        {
            path: '/',
            element: (
                <AppLayout />
            ),
            children: [
                { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
                { path: 'home', element: <HomePage /> },
            ]
        },
        // Protected Admin Interface
        {
            path: '/admin',
            element: (
                <AdminPage />
            ),
        },
    ])
}