import { makeObservable, observable, action } from "mobx"

class LayoutStore {
    shouldHideAppHeader = false;
    shouldHideHorizontalNav = false;

    constructor() {
        makeObservable(this, {
            shouldHideAppHeader: observable,
            shouldHideHorizontalNav: observable,
            reset: action,
            hideAppHeader: action,
            hideHorizontalNav: action,
            showAppHeader: action,
            showHorizontalNav: action,
            reset: action,
        });
    }

    reset = () => {
        this.shouldHideAppHeader = false;
        this.shouldHideHorizontalNav = false;
    }

    hideAppHeader = () => {
        this.shouldHideAppHeader = true;
    }

    hideHorizontalNav = () => {
        this.shouldHideHorizontalNav = true;
    }

    showAppHeader = () => {
        this.shouldHideAppHeader = false;
    }

    showHorizontalNav = () => {
        this.shouldHideHorizontalNav = false;
    }

    reset = () => {
        this.shouldHideAppHeader = false;
        this.shouldHideHorizontalNav = false;
    }

}

const layoutStore = new LayoutStore();

export default layoutStore;