import { ALL_USERS_MATCHING_CHANNEL, CREATE_MESSAGE_REQUEST, MATCH_REQUEST, READ_MATCH_MESSAGES_REQUEST, READ_MESSAGE_REQUEST } from '../../constants/ably-events';
import axios from '../../utils/axios';

export class ApiLayer {
    /* Question */
    postQuestion = (content) => axios.post("/question", { content });
    searchQuestions = () => axios.post("/question/search", {});
    patchQuestion = ({ question_id, content }) => axios.patch(`/question/${question_id}`, { content });
    submitUserQuestion = (submission) => axios.post("/question/user-submission", { submission });
    approvePendingQuestion = ({ question_id }) => axios.patch(`/question/${question_id}/approve`);
    deleteQuestion = ({ question_id }) => axios.delete(`/question/${question_id}`);
    makeQuestionNextActive = ({ question_id }) => axios.patch(`/question/${question_id}/make-next-active`);
    startDailyQuestions = () => axios.post("/question/start-daily-questions");

    manualNewDailyQuestionCheck = () => axios.post("/question/manual-daily-question-check");

    /* QuestionSubscription */
    postQuestionSubscription = ({ question_id, answer }) => axios.post("/question-subscription/", { question_id, answer });
    findQuestionSubscription = (question_id) => axios.get(`/question-subscription/${question_id}`);
    findAllQuestionSubscriptions = (question_id) => axios.get(`/question-subscription/all/${question_id}`);

    /* Match/Friend */
    searchMatches = (query) => axios.post("/match/search", { query });
    findOneMatch = ({ match_id, query }) => axios.post(`/match/${match_id}`, { query });
    removeMatch = ({ user_id }) => axios.delete(`/match/${user_id}`);
    conversedAboutQuestion = ({ currentUserQuestionSubscription, partnerQuestionSubscription }) => axios.post("/match/conversedAboutQuestion",
        {

            currentUserQuestionSubscription,
            partnerQuestionSubscription
        }
    );

    /* Profile */
    findProfile = ({ username }) => axios.get(`/profile/${username}`);
    patchProfile = ({ username, update }) => axios.patch(`/profile/${username}`, { update });

    /* Count */
    getUnreadMessageCount = () => axios.get("message/unread/count");

    /* Auth */
    deleteUser = () => axios.delete("/auth/deleteUser");

    /* ABLY - ably/channel_name/event_name */

    /* all_users_matching_channel */
    matchRequest = ({ user_id, currentUserQuestionSubscription, partnerQuestionSubscription }) =>
        axios.post(
            `ably/${ALL_USERS_MATCHING_CHANNEL}/${MATCH_REQUEST}`,
            {
                user_id,
                currentUserQuestionSubscription,
                partnerQuestionSubscription
            }
        );

    /* match channel */
    createMessageRequest = ({ match_id, author_id, recipientId, content }) =>
        axios.post(
            `ably/match/${CREATE_MESSAGE_REQUEST}`,
            {
                match_id,
                author_id,
                recipientId,
                content
            }
        );

    readMessageRequest = ({ message_id, user_id, match_id, }) =>
        axios.post(
            `ably/match/${READ_MESSAGE_REQUEST}`,
            {
                match_id,
                user_id,
                message_id,
            }
        );

    readMatchMessagesRequest = ({ match_id, author_id, startingMessageId }) =>
        axios.post(
            `ably/match/${READ_MATCH_MESSAGES_REQUEST}`,
            {
                match_id,
                author_id,
                startingMessageId,
            }
        );

};

const apiLayer = new ApiLayer();

export default apiLayer;