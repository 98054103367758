import { action, makeObservable, observable } from "mobx"

class QuestionSubscriptionStore {
    questionAnswerMap = new Map(); // maps question_id to answer of current user

    constructor() {
        makeObservable(this, {
            questionAnswerMap: observable,
            setQuestionAnswer: action,
            reset: action,
        });
    }

    setQuestionAnswer = ({ question_id, answer }) => {
        this.questionAnswerMap.set(question_id, answer);
    }

    reset = () => {
        this.questionAnswerMap = new Map();
    }
}

const questionSubscriptionStore = new QuestionSubscriptionStore();

export default questionSubscriptionStore;