
import { questionStore } from "./question-store";
import { questionSubscriptionStore } from "./question-subscription-store";
import { matchStore } from "./match-store";
import { layoutStore } from "./layout-store";

const stores = {
    questionStore,
    questionSubscriptionStore: questionSubscriptionStore,
    matchStore: matchStore,
    layoutStore: layoutStore,
};

export default stores;
